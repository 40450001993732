document.addEventListener('DOMContentLoaded', () => {
  initFloatingFooter();
});

/* ========  Start Floating Footer ======== */

function initFloatingFooter() {
  addEventListener('scroll', addShowClassToFloatingFooter, { passive: true });
}

function addShowClassToFloatingFooter() {
  let floatingFooter = document.getElementById('floating-footer');
  if (!floatingFooter) return;
  floatingFooter.classList.add('show');
  removeEventListener('scroll', addShowClassToFloatingFooter);
}